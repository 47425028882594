import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/common.css';
import ReactPaginate from 'react-paginate';
import { Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  baseUri,
  showLoader,
  hideLoader,
  authCheck,
  dateTimeFormat,
  htmlDateTime,
  calculateTime,
  measures,
  checkMax,
  buildQuery,
  checkNumber,
  ADMIN,
  RestrictedEditUsers,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;
const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const initialIndent = {
  item: '',
  defaultItem: [],
  quantity: '',
  receivedFromStore: false,
  intendNumber: '',
  uom: '',
  defaultReceivedFromStore: [{ label: 'No', value: false }],
};

const initialOperations = {
  group: '',
  defaultGroup: [],
  operation: '',
  operationOptions: [],
  defaultOperation: [],
  startTime: '',
  endTime: '',
  duration: '',
  valid: true,
  indent: [],
  randomNum: '',
  isEditable: true,
};

const initialFormInput = {
  machine: '',
  supervisor: '',
  operator: '',
  jobCardArray: [
    {
      material: 0,
      maxmaterial: 0,
      labelField: { label: '', value: '' },
    },
  ],
  macOps: [],
};

const PdfComponent = ({
  tableTitles,
  tableContents,
  totalWeight,
  totalMeters,
  tableHeader,
  shade,
  id,
}: {
  tableTitles: Array<string>;
  tableContents: Array<Array<any>>;
  totalWeight: string;
  totalMeters: number;
  tableHeader: Array<any>;
  shade: string;
  id: string;
}) => {
  const borderColor = '#bff0fd';
  const styles = StyleSheet.create({
    document: {
      color: 'black',
      fontFamily: 'Courier',
    },
    page: {
      padding: '10px',
    },
    header: {
      display: 'flex',
      fontSize: '10px',
      fontWeight: 'extrabold',
      flexDirection: 'row',
      fontFamily: 'Courier-Bold',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '6px',
      borderBottomColor: borderColor,
      borderBottom: 2,
    },
    titleContainer: {
      flexDirection: 'row',
      borderBottomColor: '#bff0fd',
      backgroundColor: '#bff0fd',
      borderBottomWidth: 1,
      alignItems: 'center',
      fontSize: '8px',
      height: 20,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
    },
    reportTitle: {
      width: `${Math.abs(100 / tableTitles.length)}%`,
      borderRightColor: '#90e5fc',
      textTransform: 'uppercase',
    },
    row: {
      flexDirection: 'row',
      borderBottomColor: '#bff0fd',
      borderBottomWidth: 1,
      alignItems: 'center',
      textAlign: 'left',
      height: 11,
      fontStyle: 'bold',
      flexGrow: 1,
    },
    col: {
      width: `${Math.abs(100 / tableTitles.length)}%`,
      borderRightColor: borderColor,
      borderRightWidth: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '7px',
      display: 'flex',
      flexWrap: 'wrap',
    },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 24,
      borderWidth: 1,
      borderColor: '#bff0fd',
    },
    alignCenter: {
      textAlign: 'center',
    },
  });
  const widthMap: any = {
    Process: { width: '15%' },
    'Ind No': { width: '20%' },
    'Item Code': { width: '12%' },
    'Item Name': { width: '30%' },
    Qty: { width: '15%' },
    Rec: { width: '6%' },
    'GPL Qty': { textAlign: 'right', paddingRight: 2 },
    'Qty %': { textAlign: 'right', paddingRight: 2 },
    'M.Pre': { textAlign: 'right', paddingRight: 2, width: '8%' },
  };
  return (
    <Document style={styles.document}>
      <Page size="A5" style={styles.page} orientation="portrait">
        <View fixed style={styles.header}>
          <View
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              alignSelf: 'flex-start',
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              UNITED BLEACHERS LIMITED, METTUPALAYAM
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              {tableHeader.map((t, i) => (
                <Text
                  style={{
                    fontWeight: 'bold',
                    fontSize: '8px',
                    marginTop: '2px',
                  }}
                  key={i}
                >
                  {t.title} - {t.meters} Mts
                </Text>
              ))}
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '8px',
                  marginTop: '2px',
                }}
              >
                MUID: {id}
              </Text>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '8px',
                  marginTop: '2px',
                }}
              >
                Shade: {shade}
              </Text>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '8px',
                  marginTop: '2px',
                }}
              >
                Total Meters:{totalMeters} Mts Total Weight:{totalWeight}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            <Text
              style={{ textAlign: 'right' }}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            />
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: '8px',
              }}
            >
              Consumption Slip: {moment(new Date().toISOString()).format('DD/MM/YYYY')}
            </Text>
          </View>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.titleContainer}>
            {tableTitles.map((t, i) => (
              <Text key={i} style={{ ...styles.reportTitle, ...widthMap[t], ...styles.alignCenter }}>
                {t}
              </Text>
            ))}
          </View>
          {tableContents.map((row, i) => (
            <View style={{ ...styles.row, height: row[2] === 'Water' ? 15 : 11 }} key={i}>
              {row.map((value, i) => (
                <Text
                  style={{
                    ...styles.col,
                    ...widthMap[tableTitles[i]],
                    height: '100%',
                    overflow: 'hidden',
                    borderRightWidth: i === row.length - 1 ? 0 : 1,
                    fontFamily: i === 1 && row[2] === 'Water' ? 'Courier-Bold' : 'Courier',
                    fontWeight: i === 1 && row[2] === 'Water' ? 'bold' : 'normal',
                    fontSize: i === 1 && row[2] === 'Water' ? '10px' : '7px',
                  }}
                  key={i}
                  break
                >
                  {value}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

const MachineUtilization = () => {
  let totMeters = 0;
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [formInput, setFormInput] = useState(() => ({ ...initialFormInput }));
  const [pickId, setPickId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const [defaultMachine, setDefaultMachine] = useState([]);
  const [machineOptions, setMachineOptions] = useState([]);
  const [jobCardOptions, setJobCardOptions] = useState([]);

  const [machineGroups, setMachineGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [allGroupOperationOptions, setAllGroupOperationOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [weightPercent, setWeighPercent] = useState(0);
  const [totWeight, setTotWeight] = useState(0);
  const [pdfData, setPdfData] = useState({
    tableRows: [],
    tableHeader: [{ title: '', meters: 0 }],
    totalWeight: '0',
    totalMeters: '0',
    shade: '',
    id: '',
  });

  const sampleBoolean = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  const measureOptions = measures();

  useEffect(() => {
    authCheck(authState.authData);
  }, []);

  useEffect(() => {
    getApi();
  }, [page]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const handlePrint = (id: any) => {
    axios
      .get(
        baseUri() +
          `machine-utilizations/${id}?populate[0]=jobCard&populate[1]=macOps&populate[2]=macOps.group&populate[3]=macOps.operation&populate[4]=macOps.indent&populate[5]=macOps.indent.item&populate[6]=machine&populate[7]=machine.groupOps&populate[8]=machine.groupOps.group&populate[9]=machine.groupOps.operations&populate[10]=jobCard.processedGreys&populate[11]=jobMaterial&populate[12]=jobMaterial.jobCard.id&populate[13]=jobMaterial.jobCard.idLotNum&populate[14]=jobMaterial.jobCard.processInstruction.customer.name&populate[15]=jobMaterial.jobCard.processInstruction.customerSort.sortName&populate[16]=jobMaterial.jobCard.processOrder.customer.name&populate[17]=jobMaterial.jobCard.processOrder.customersSort.sortName`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response: any) => {
        response = response.data.data;
        const id = response.id;
        const rows: any = [];
        let prevIndNum = '';
        let prevProcess = '';
        let waterRow = [];
        let shade = response?.attributes?.jobMaterial[0]?.jobCard?.data?.attributes?.processOrder?.data?.attributes
          ?.shade
          ? response?.attributes?.jobMaterial[0]?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.shade
          : response?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.shade;
        response.attributes.macOps.forEach((operation: any) => {
          operation.indent.forEach((t: any) => {
            let currProcess = '';
            if (operation.group.data.attributes.description !== prevProcess) {
              prevProcess = operation.group.data.attributes.description;
              currProcess = prevProcess;
              if (rows.length > 0) {
                rows.push([]);
              }
            }
            let currIndNumber = '';
            if (t.intendNumber !== prevIndNum) {
              currIndNumber = t.intendNumber;
              prevIndNum = t.intendNumber;
            }
            if (+t.item.data.attributes.itemCode === 999999) {
              waterRow = [
                currProcess,
                `${t.mix_prep}-${(+t.mix_prep / +response?.attributes.totWeight || 1).toFixed(2)}`,
                t.item.data.attributes.itemName,
                currIndNumber,
                t.pct_qty_in_gpl,
                t.pct_qty_in_percentage,
                t.mix_prep,
                `${t.quantity.toFixed(3)} ${measureOptions[+t.item.data.attributes.measure - 1]['label']}`,
                t.receivedFromStore ? 'Y' : 'N',
              ];
            }
            if (+t.item.data.attributes.itemCode !== 999999) {
              rows.push([
                currProcess,
                currIndNumber,
                t.item.data.attributes.itemCode,
                t.item.data.attributes.itemName,
                (+t.pct_qty_in_gpl).toFixed(3),
                t.pct_qty_in_percentage,
                t.mix_prep,
                `${t.quantity.toFixed(3)} ${measureOptions[+t.item.data.attributes.measure - 1]['label']}`,
                t.receivedFromStore ? 'Y' : 'N',
              ]);
            } else {
              rows.push(waterRow);
            }
          });
        });
        const tableHeader = [];
        if (response?.attributes?.jobCard?.data) {
          tableHeader.push({
            title: getLabelById(response.attributes.jobCard.data),
            meters: response.attributes?.material || 0,
          });
        }
        response.attributes.jobMaterial.forEach((t) => {
          tableHeader.push({
            title: getLabelById(t.jobCard.data),
            meters: t.material,
          });
        });
        setPdfData({
          tableRows: rows,
          tableHeader: tableHeader,
          totalWeight: response?.attributes.totWeight ? `${+response.attributes.totWeight.toFixed(2)}` : '0.00',
          totalMeters: response.attributes.material,
          shade: shade,
          id: id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchInitData = () => {
    showLoader();
    let config = {
      headers: {
        authorization: 'Bearer ' + authState.authData,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    Promise.all([
      axios.get(
        baseUri() +
          `machines?sort[0]=id:desc&[fields][0]=machineCode&[fields][1]=description&populate[groupOps][fields][0]=id&populate[groupOps][populate][group][fields][0]=groupCode&populate[groupOps][populate][group][fields][0]=description&populate[groupOps][populate][operations][fields][0]=operationCode&populate[groupOps][populate][operations][fields][1]=description`,
        config,
      ),
      axios.get(
        baseUri() +
          'job-cards?sort[0]=id:desc&fields[0]=greyWeightPerMtr&fields[1]=remarks&fields[2]=idLotNum&populate[processInstruction][fields][0]=piNumber&populate[processInstruction][populate][customer][fields][0]=name&populate[processInstruction][populate][customerSort][fields][0]=sortName&populate[processOrder][populate][customer][fields][0]=name&populate[processOrder][populate][customersSort][fields][0]=sortName&populate[processedGreys][fields][0]=metersProcessed&filters[status][$eq]=open',
        config,
      ),
      axios.get(baseUri() + 'items?fields[0]=itemCode&fields[1]=itemName&fields[2]=measure', config),
    ]).then((response) => {
      getMachines(response[0]);
      getJobCards(response[1]);
      getItems(response[2]);
      hideLoader();
    });
  };
  const AddOperation = () => {
    let initialOp = { ...initialOperations };
    initialOp.randomNum = Date.now();
    let newOps = [...formInput.macOps, { ...initialOp }];
    setFormInput({ ...formInput, macOps: newOps });
  };

  const addItem = (key) => {
    let ops = formInput.macOps[key];
    let initialItem = { ...initialIndent };
    initialItem.intendNumber = ops.randomNum;
    let newIndent = [...ops.indent, { ...initialItem }];
    let copy = formInput.macOps.map((element, ki) => {
      if (ki == key) {
        element.indent = newIndent;
      }
      return element;
    });
    setFormInput({
      ...formInput,
      macOps: copy,
    });
  };

  const removeOperations = (index) => {
    let operations = formInput.macOps.filter((value, key) => {
      return key !== index;
    });
    setFormInput({ ...formInput, macOps: operations });
  };

  const removeItems = (ops, item) => {
    let options = formInput.macOps.filter((value, key) => {
      return key === ops;
    });
    let items = options[0].indent.filter((value, key) => {
      return key !== item;
    });
    formInput.macOps[ops].indent = items;
    setFormInput({ ...formInput, macOps: formInput.macOps });
  };

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    showMachineUtil(id);
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          {showData.attributes.jobCard && (
            <div className="div-part1 operation-list">
              <h5>JobCard</h5>
              <div className="div-part1 operation-part">
                <table className="stripped bordered hover pioperations-table">
                  <thead>
                    <tr>
                      <th width="150px">GreyWeightPerMtr</th>
                      <th width="100px">jobCardDate</th>
                      <th width="100px">labourHours</th>
                      <th width="100px">MachineHours</th>
                      <th width="100px">remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{showData.attributes.jobCard.data.attributes.greyWeightPerMtr}</td>
                      <td>{dateFormat(showData.attributes.jobCard.data.attributes.jobCardDate)}</td>
                      <td>{showData.attributes.jobCard.data.attributes.labourHours}</td>
                      <td>{showData.attributes.jobCard.data.attributes.machineHours}</td>
                      <td>{showData.attributes.jobCard.data.attributes.remarks[0]['value']}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {showData.attributes.macOps && (
            <div className="div-part1 operation-list">
              <h5>Machine Operations</h5>
              <div className="div-part1 operation-part">
                <table className="stripped bordered hover pioperations-table">
                  <thead>
                    <tr>
                      <th width="60px">Group</th>
                      <th width="100px">Operation</th>
                      <th width="150px">Running Hours</th>
                      <th width="200">Indent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showData.attributes.macOps &&
                      showData.attributes.macOps.map((row, i) => (
                        <tr key={'groups-' + i}>
                          <td>{row.group.data.attributes.groupCode + ' - ' + row.group.data.attributes.description}</td>
                          <td>
                            {row.operation.data.attributes.operationCode +
                              ' - ' +
                              row.operation.data.attributes.description}
                          </td>
                          <td>
                            <p>Start: {dateTimeFormat(row.startTime)}</p>
                            <p>End: {dateTimeFormat(row.endTime)}</p>
                          </td>
                          <td>
                            {row.indent &&
                              row.indent.map((inds, ky) => (
                                <div className="indent-item" key={'indent' + ky}>
                                  <p>Quantity: {inds.quantity}</p>
                                  <p>
                                    Item:{' '}
                                    {inds.item.data.attributes.itemCode + ' - ' + inds.item.data.attributes.itemName}
                                  </p>
                                  <p>Received From Store: {inds.receivedFromStore ? 'Yes' : 'No'}</p>
                                </div>
                              ))}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;

  const handleChange = (e) => {
    resetRequired(e);
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
  };

  const handleMaterialChange = (e, key) => {
    resetRequired(e);
    const { value } = e.target;
    const jobCardObj = { ...formInput.jobCardArray[key], material: value };
    const currentJobCardArray = formInput.jobCardArray;
    currentJobCardArray[key] = jobCardObj;
    setFormInput((prev) => ({ ...prev, jobCardArray: currentJobCardArray }));
  };

  const groupInputChange = (e, index) => {
    resetRequired(e);
    const { name, value } = e.target;
    formInput.macOps[index][name] = value === null ? '' : value;
    let time = calculateTime(formInput.macOps[index]['startTime'], formInput.macOps[index]['endTime']);
    formInput.macOps[index]['duration'] = time.duration;
    formInput.macOps[index]['valid'] = time.valid;
    setFormInput({ ...formInput, macOps: formInput.macOps });
  };

  const childGroupChange = (event, index) => {
    if (event.value !== formInput.macOps[index]['group']) {
      formInput.macOps[index]['defaultGroup'] = { label: event.label, value: event.value };
      formInput.macOps[index]['group'] = event.value;
      formInput.macOps[index]['defaultOperation'] = [];
      formInput.macOps[index]['operation'] = '';
      formInput.macOps[index]['indent'] = [];
      formInput.macOps[index]['startTime'] = '';
      formInput.macOps[index]['endTime'] = '';
      formInput.macOps[index]['duration'] = '';
      let machineGpsOperation = allGroupOperationOptions.filter((item) => {
        return item.groupId == event.value;
      });
      let opoptions = [];
      machineGpsOperation[0].operations.forEach(function (item, index) {
        opoptions.push({ label: item.attributes.operationCode + ' - ' + item.attributes.description, value: item.id });
      });
      formInput.macOps[index]['operationOptions'] = opoptions;
      setFormInput({ ...formInput, macOps: formInput.macOps });
    }
    pullDownReset('macOps-' + index + '-group');
  };

  const childOperationChange = (event, index) => {
    if (event.value !== formInput.macOps[index]['operation']) {
      formInput.macOps[index]['defaultOperation'] = { label: event.label, value: event.value };
      formInput.macOps[index]['operation'] = event.value;
      formInput.macOps[index]['indent'] = '';
      formInput.macOps[index]['startTime'] = '';
      formInput.macOps[index]['endTime'] = '';
      formInput.macOps[index]['duration'] = '';
      setFormInput({ ...formInput, macOps: formInput.macOps });
    }
    pullDownReset('macOps-' + index + '-operation');
  };

  const itemChange = (event, ops, item) => {
    if (formInput.macOps[ops].indent[item]['item'] !== event.value) {
      formInput.macOps[ops].indent[item]['item'] = event.value;
      formInput.macOps[ops].indent[item]['defaultItem'] = { label: event.label, value: event.value };
      formInput.macOps[ops].indent[item]['uom'] = event.measure;
      formInput.macOps[ops].indent[item]['pct_qty_in_percentage'] = 0;
      formInput.macOps[ops].indent[item]['mix_prep'] = 0;
      formInput.macOps[ops].indent[item]['pct_qty_in_gpl'] = 0;
      formInput.macOps[ops].indent[item]['quantity'] = 0;
      setFormInput({ ...formInput, macOps: formInput.macOps });
    }
    pullDownReset('macOps-' + ops + '-indent-' + item + '-item');
  };

  const itemInputChange = (event, ops, item, key = 'quantity') => {
    resetRequired(event);
    const { value } = event.target;
    const currData = formInput.macOps[ops].indent[item];
    currData[key] = value;
    if (key === 'quantity') {
      currData['pct_qty_in_percentage'] = 0;
      currData['mix_prep'] = 0;
      currData['pct_qty_in_gpl'] = 0;
    } else if (+currData['pct_qty_in_percentage'] > 0) {
      const currQty = (+currData['pct_qty_in_percentage'] * +totWeight) / 100;
      currData['quantity'] = !currQty ? 0.0 : currQty;
      currData['mix_prep'] = 0;
    } else {
      if (currData['mix_prep'] >= 9999) {
        currData['mix_prep'] = 9999;
      }
      const currQty = (+currData['mix_prep'] * +currData['pct_qty_in_gpl']) / 1000;
      currData['quantity'] = !currQty ? 0 : currQty;
    }
    formInput.macOps[ops].indent[item] = currData;
    setFormInput({ ...formInput, macOps: formInput.macOps });
  };

  const itemFromStoreChange = (event, ops, item) => {
    formInput.macOps[ops].indent[item]['receivedFromStore'] = event.value;
    formInput.macOps[ops].indent[item]['defaultReceivedFromStore'] = { label: event.label, value: event.value };
    setFormInput({ ...formInput, macOps: formInput.macOps });
  };

  const machineChange = (event) => {
    if (!isEditable) return;
    if (formInput.machine !== event.value) {
      let currData = { ...formInput, macOps: [], machine: event.value };
      setFormInput(currData);
      setDefaultMachine({ label: event.label, value: event.value });
      let machineGps = machineGroups.filter((item) => {
        return item.id == event.value;
      });
      let gpoptions = [];
      let alloptions = [];

      machineGps[0].groupOps.forEach(function (item, index) {
        alloptions.push({ groupId: item.group.data.id, operations: item.operations.data });
        gpoptions.push({
          label: item.group.data.attributes.groupCode + ' - ' + item.group.data.attributes.description,
          value: item.group.data.id,
        });
      });
      setGroupOptions(gpoptions);
      setAllGroupOperationOptions(alloptions);
    }
    pullDownReset('machine');
  };

  const jobcardArrayChange = (event, key: number) => {
    if (!isEditable) return;
    let isDuplicate = false;
    formInput.jobCardArray.forEach((t) => {
      if (+t.labelField.value === +event.value) {
        isDuplicate = true;
        return;
      }
    });
    if (isDuplicate) return;
    const jobCardObj = {
      labelField: {
        value: event.value,
        label: event.label,
      },
      material: event.materiel,
      maxmaterial: event.materiel,
    };
    const currentJobCardArray = [...formInput.jobCardArray];
    currentJobCardArray[key] = jobCardObj;
    setFormInput({ ...formInput, jobCardArray: currentJobCardArray });
    pullDownReset(`jobCardArray-${key}-labelField-value`);
  };

  const addNewLot = () => {
    const currJbCrdArray = [...formInput.jobCardArray];
    currJbCrdArray.push({
      material: 0,
      labelField: {
        value: '',
        label: '',
      },
      maxmaterial: 0,
    });
    setWeighPercent(0);
    setTotWeight(0);
    setFormInput({ ...formInput, jobCardArray: currJbCrdArray });
  };

  const deleteLot = (key: number) => {
    const currentJobCardArray = formInput.jobCardArray;
    currentJobCardArray.splice(key, 1);
    setFormInput({ ...formInput, jobCardArray: currentJobCardArray });
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setIsEditable(true);
    setDefaultMachine([]);
    setTotWeight(0);
    setWeighPercent(0);
    setPickId('');
    resetRequired();
    setFormInput(initialFormInput);
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'machine-utilizations?populate[0]=machine&populate[1]=jobCard&populate[2]=jobCard.processInsturction&populate[3]=jobMaterial.jobCard&populate[4]=jobMaterial.jobCard.processInstruction&sort[0]=id:desc&pagination[page]=' +
              page +
              '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
        if (page == 1 && totalPage == 0) {
          fetchInitData();
        }
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getMachines = (response) => {
    let options = [];
    let groupInfo = [];
    response.data.data.forEach(function (item, index) {
      options.push({ label: item.attributes.machineCode + ' - ' + item.attributes.description, value: item.id });
      groupInfo.push({ id: item.id, groupOps: item.attributes.groupOps });
    });
    setMachineOptions(options);
    setMachineGroups(groupInfo);
  };

  const getJobCards = (response) => {
    let jobcardoptions = [];
    response.data.data.forEach(function (item, index) {
      let totalMeters = 0;
      if (item.attributes.processedGreys.data) {
        item.attributes.processedGreys.data.forEach(function (grey, key) {
          totalMeters = totalMeters + parseInt(grey.attributes.metersProcessed);
        });
      }
      let customer = '';
      let customerSort = '';

      if (item.attributes.processInstruction.data) {
        customer = item.attributes.processInstruction.data.attributes.customer.data.attributes.name;
        customerSort = item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName;
      }
      if (item.attributes.processOrder.data) {
        customer = item.attributes.processOrder.data.attributes.customer.data.attributes.name;
        customerSort = item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName;
      }
      jobcardoptions.push({
        label: `${item.attributes.idLotNum} - ${item.id} - ${customer} - ${customerSort}`,
        value: item.id,
        materiel: totalMeters,
      });
    });
    setJobCardOptions(jobcardoptions);
  };

  const getItems = (response) => {
    let options = [];
    response.data.data.forEach(function (item, index) {
      options.push({
        label: item.attributes.itemCode + ' - ' + item.attributes.itemName,
        value: item.id,
        measure: measureOptions.find((o) => o.value === item.attributes.measure).label,
      });
    });
    setItemOptions(options);
  };

  const postApi = () => {
    const postData = {
      data: {
        ...formInput,
        jobMaterial: [],
      },
    };
    const jobMaterialArray = [];
    postData.data.jobMaterial = [];
    let isDateValid = true;
    postData.data.macOps.forEach((data) => {
      if (!data.valid) {
        isDateValid = false;
        return false;
      }
    });

    let validate = requireCheck(postData);
    if (!validate || postData.data.macOps.length == 0 || !isDateValid) {
      if (postData.data.macOps.length == 0) {
        alert('Kindly Add Operation');
      }
      return false;
    }

    if (postData.data.jobCard) {
      jobMaterialArray.push({
        jobCard: postData.data.jobCard,
        material: postData.data.jobMaterial,
      });
    }
    for (const key in postData['data']) {
      if (key == 'macOps') {
        postData['data'][key].map((item, kii) => {
          for (const mKeys in item) {
            if (item[mKeys] == '') {
              delete item[mKeys];
            }
          }
        });
      } else {
        if (postData['data'][key] == '') {
          delete postData['data'][key];
        }
      }
    }
    postData.data.jobCardArray.forEach((currData) => {
      jobMaterialArray.push({
        jobCard: currData.labelField.value,
        material: currData.material,
      });
    });
    postData.data.jobMaterial = jobMaterialArray;
    postData.data.material = delete postData.data.material;
    delete postData.data.jobCard;
    delete postData.data.jobCardArray;
    postData.data.material = totMeters;
    postData.data.jobCard = null;
    postData.data.totWeight = totWeight;
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'machine-utilizations' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'machine-utilizations', postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const getLabelById = (item) => {
    let customer = '';
    let customerSort = '';
    if (item?.attributes?.processInstruction?.data) {
      customer = item?.attributes?.processInstruction?.data?.attributes?.customer?.data?.attributes?.name;
      customerSort = item?.attributes?.processInstruction?.data?.attributes?.customerSort?.data?.attributes?.sortName;
    } else if (item?.attributes?.processOrder?.data) {
      customer = item?.attributes?.processOrder?.data?.attributes?.customer?.data?.attributes?.name;
      customerSort = item?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName;
    }
    return `${item?.attributes?.idLotNum} - ${item?.id} - ${customer} - ${customerSort}`;
  };

  const editMachineUtil = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(
        baseUri() +
          `machine-utilizations/${id}?populate[0]=jobCard&populate[1]=macOps&populate[2]=macOps.group&populate[3]=macOps.operation&populate[4]=macOps.indent&populate[5]=macOps.indent.item&populate[6]=machine&populate[7]=machine.groupOps&populate[8]=machine.groupOps.group&populate[9]=machine.groupOps.operations&populate[10]=jobCard.processedGreys&populate[11]=jobMaterial&populate[12]=jobMaterial.jobCard.id&populate[13]=jobMaterial.jobCard.idLotNum&populate[14]=jobMaterial.jobCard.processInstruction.customer.name&populate[15]=jobMaterial.jobCard.processInstruction.customerSort.sortName&populate[16]=jobMaterial.jobCard.processOrder.customer.name&populate[17]=jobMaterial.jobCard.processOrder.customersSort.sortName&populate[18]=jobCard.idLotNum&populate[19]=jobCard.processInstruction.customer.name&populate[20]=jobCard.processInstruction.customerSort.sortName&populate[21]=jobCard.processOrder.customer.name&populate[22]=jobCard.processOrder.customersSort.sortName`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response: any) => {
        response = response.data.data;
        setSave(pageAccess.edit === 1);
        setPickId(response.id);
        hideLoader();
        const jobMaterialArray = [];
        let totalMeters = 0;
        if (response.attributes?.jobCard.data?.attributes?.processedGreys.data) {
          response.attributes.jobCard.data.attributes.processedGreys.data.forEach(function (grey, key) {
            totalMeters = totalMeters + parseInt(grey.attributes.metersProcessed);
          });
        }
        if (response.attributes.jobCard.data) {
          jobMaterialArray.push({
            material: response.attributes?.material || 0,
            maxmaterial: totalMeters,
            labelField: {
              label: getLabelById(response.attributes?.jobCard.data),
              value: response.attributes.jobCard.data.id,
            },
          });
        }
        response.attributes.jobMaterial.forEach((t) => {
          let totalMeters = 0;
          if (t.jobCard.data.attributes?.processedGreys?.data) {
            t.jobCard.data.attributes.processedGreys.data.forEach(function (grey, key) {
              totalMeters = totalMeters + parseInt(grey.attributes.metersProcessed);
            });
          }
          jobMaterialArray.push({
            material: t.material,
            maxmaterial: totMeters,
            labelField: { label: getLabelById(t?.jobCard.data), value: t.jobCard.data.id },
          });
        });
        let gpoptions = [];
        let alloptions = [];
        let machineGroupOps = response.attributes.machine.data.attributes.groupOps;
        machineGroupOps.forEach(function (item, index) {
          alloptions.push({ groupId: item.group.data.id, operations: item.operations.data });
          gpoptions.push({
            label: item.group.data.attributes.groupCode + ' - ' + item.group.data.attributes.description,
            value: item.group.data.id,
          });
        });
        setGroupOptions(gpoptions);
        setAllGroupOperationOptions(alloptions);

        setDefaultMachine({
          label:
            response.attributes.machine.data.attributes.machineCode +
            ' - ' +
            response.attributes.machine.data.attributes.description,
          value: response.attributes.machine.data.id,
        });
        const aa = Object.assign({}, response.attributes);
        aa.jobCardArray = jobMaterialArray;
        let isEditable = true;
        aa.macOps.map((operations, index) => {
          aa.macOps[index]['randomNum'] = Date.now();
          aa.macOps[index]['startTime'] = operations['startTime'] === null ? '' : htmlDateTime(operations['startTime']);
          aa.macOps[index]['endTime'] = operations['endTime'] === null ? '' : htmlDateTime(operations['endTime']);
          let time = calculateTime(operations['startTime'], operations['endTime']);
          aa.macOps[index]['duration'] = time.duration;
          aa.macOps[index]['valid'] = time.valid;
          aa.macOps[index]['defaultGroup'] = {
            label:
              operations['group']['data']['attributes']['groupCode'] +
              ' - ' +
              operations['group']['data']['attributes']['description'],
            value: operations['group']['data']['id'],
          };
          if (RestrictedEditUsers.includes(authState.authEmail) && operations['endTime']) {
            isEditable = false;
          }
          aa.macOps[index]['isEditable'] = isEditable;
          let machineGpsOperation = alloptions.filter((item) => {
            return item.groupId == operations['group']['data']['id'];
          });
          let opoptions = [];
          machineGpsOperation[0].operations.forEach(function (item, index) {
            opoptions.push({
              label: item.attributes.operationCode + ' - ' + item.attributes.description,
              value: item.id,
            });
          });
          aa.macOps[index]['operationOptions'] = opoptions;
          aa.macOps[index]['defaultOperation'] = {
            label:
              operations['operation']['data']['attributes']['operationCode'] +
              ' - ' +
              operations['operation']['data']['attributes']['description'],
            value: operations['operation']['data']['id'],
          };
          operations.indent.map((item, index2) => {
            aa.macOps[index]['indent'][index2]['defaultItem'] = {
              label:
                item['item']['data']['attributes']['itemCode'] + ' - ' + item['item']['data']['attributes']['itemName'],
              value: item['item']['data']['id'],
            };
            aa.macOps[index]['indent'][index2]['uom'] = measureOptions.find(
              (o) => o.value === item['item']['data']['attributes']['measure'],
            ).label;
            aa.macOps[index]['indent'][index2]['item'] = item['item']['data']['id'];
            aa.macOps[index]['indent'][index2]['defaultReceivedFromStore'] = {
              label: item['receivedFromStore'] ? 'Yes' : 'No',
              value: item['receivedFromStore'] ? true : false,
            };
            aa.macOps[index]['indent'][index2]['quantity'] = item.quantity.toFixed(3);
          });
          aa.macOps[index]['operation'] = operations['operation']['data']['id'];
          aa.macOps[index]['group'] = operations['group']['data']['id'];
        });
        delete aa.material;
        delete aa.jobCard;
        delete aa.jobMaterial;
        setFormInput(aa);
        setTotWeight(response.attributes?.totWeight ? response.attributes.totWeight : 0);
        setIsEditable(isEditable);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        console.log(error);
        //return  error;
      });
  };

  const getWeight = () => {
    const jobCardsArray: Array<string> = [];
    if (formInput.jobCardArray[0].labelField.label === '') {
      toast.dismiss();
      toast.error('Please select atleast one Lot Number');
      return;
    }
    formInput.jobCardArray.forEach((t) => {
      if (t.labelField.label !== '') {
        jobCardsArray.push(t?.labelField?.value);
      }
    });
    const jbCrd = jobCardsArray.toString();
    axios
      .get(baseUri() + `machine-utilization/pi?job_cards=${jbCrd}`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const result = response.data.data.result;
        if (result.length !== 1) {
          toast.dismiss();
          toast('Lot Numbers selected have Different PI numbers. Setting First one');
        }
        setWeighPercent(result[0].grey_weight_per_mtr);
      });
  };

  const getItemsFromJobCard = (idx: number) => {
    toast.dismiss();
    toast.loading('Fetching Items...');
    const jobCardsArray: Array<string> = [];
    if (formInput.macOps[idx].operation === '') {
      toast.dismiss();
      toast.error('Please select operation');
      return;
    }
    const currOperation = formInput.macOps[idx].operation;
    if (formInput.jobCardArray[0].labelField.label === '') {
      toast.dismiss();
      toast.error('Please select atleast one Lot Number');
      return;
    }
    formInput.jobCardArray.forEach((t) => {
      if (t.labelField.label !== '') {
        jobCardsArray.push(t?.labelField?.value);
      }
    });
    const jbCrd = jobCardsArray.toString();
    axios
      .get(baseUri() + `machine-utilization/pi?job_cards=${jbCrd}`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const result = response.data.data.result;
        if (result.length !== 1) {
          toast.dismiss();
          toast('Lot Numbers selected have Different PI numbers');
        }
        const currPiNumber = result[0].pi_number;
        const currWeight = result[0].grey_weight_per_mtr;
        setWeighPercent(result[0].grey_weight_per_mtr);
        axios
          .get(baseUri() + `machine-utilization/items?pi_number=${currPiNumber}&opid=${currOperation}`, {
            headers: {
              authorization: 'Bearer ' + authState.authData,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            try {
              const result = response.data.data.result;
              toast.dismiss();
              toast.success('Successfully fetched');
              const currIndentArray = [];
              const currIndentNumber = Date.now();
              result.forEach((t, idx) => {
                let item = itemOptions.filter((currValue) => {
                  return currValue.label === `${t.item_code} - ${t.item_name}`;
                });
                item = item[0];
                let currQuantity = 0;
                const currTotWeight = `${((+totMeters * +currWeight) / 1000).toFixed(2)}`;
                if (+t.pct_qty_in_percentage > 0) {
                  currQuantity = (+currTotWeight * +t.pct_qty_in_percentage) / 100;
                } else {
                  t.mix_prep = t.mix_prep ? +t.mix_prep : 0;
                  currQuantity = (+t.mix_prep * +t.pct_qty_in_gpl) / 1000;
                }
                const indentObj = {
                  item: item.value,
                  defaultItem: {
                    label: item.label,
                    value: item.value,
                  },
                  quantity: `${+currQuantity.toFixed(3)}`,
                  receivedFromStore: false,
                  intendNumber: currIndentNumber,
                  uom: item.measure,
                  defaultReceivedFromStore: [
                    {
                      label: 'No',
                      value: false,
                    },
                  ],
                  pct_qty_in_percentage: t.pct_qty_in_percentage,
                  pct_qty_in_gpl: t.pct_qty_in_gpl,
                  mix_prep: t.mix_prep || 0,
                };
                currIndentArray.push(indentObj);
              });
              setFormInput((prev) => {
                let temp = { ...prev };
                temp.macOps[idx].indent = currIndentArray;
                return temp;
              });
            } catch (err) {
              console.log(err);
            }
          });
      });
  };

  const showMachineUtil = (id) => {
    showLoader();
    axios
      .get(
        baseUri() +
          `machine-utilizations/${id}?populate[0]=jobCard&populate[1]=macOps&populate[2]=macOps.group&populate[3]=macOps.operation&populate[4]=macOps.indent&populate[5]=macOps.indent.item`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setShowData(response.data.data);
        setIsModalOpen(true);
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteMachineUtil = (id) => {
    const removeGroup = window.confirm('Would you like to delete this record?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'machine-utilizations/' + id, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            id={'editData' + row.id}
            onClick={() => {
              editMachineUtil(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteMachineUtil(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtnprint-color"
            onClick={() => {
              handlePrint(row.id);
            }}
          >
            {' '}
            <EvaIcon name="printer-outline" />{' '}
          </ActionBtn>
        </div>
      ),
      maxWidth: '45%',
    },
    {
      name: 'ID',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Machine',
      selector: (row) =>
        `${
          row.attributes.machine.data.attributes.machineCode +
          ' - ' +
          row.attributes.machine.data.attributes.description
        }`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Job Card',
      selector: (row) =>
        `${[
          ...row.attributes.jobMaterial.map((t) => t?.jobCard?.data?.id || ''),
          row.attributes.jobCard?.data?.id || '',
        ]
          .filter((t) => t !== '')
          .join(',')}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Lot Num',
      selector: (row) =>
        `${[
          ...row.attributes.jobMaterial.map((t) => t.jobCard?.data?.attributes?.idLotNum || ''),
          row.attributes.jobCard?.data?.attributes?.idLotNum || '',
        ]
          .filter((t) => t !== '')
          .join(',')}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'PI',
      selector: (row) =>
        `${[
          ...row.attributes.jobMaterial.map((t) => t?.jobCard?.data?.attributes?.processInstruction?.data?.id || ''),
          row?.attributes?.jobCard?.data?.attributes?.processInstruction?.data?.id || '',
        ]
          .filter((t) => t !== '')
          .join(',')}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Supervisor',
      selector: (row) => `${row?.attributes?.supervisor}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Operator',
      selector: (row) => `${row?.attributes?.operator}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Material',
      selector: (row) => `${row?.attributes?.material ? row.attributes.material : ''}`,
      sortable: true,
      maxWidth: '20%',
    },
  ];

  const filterColumns = [
    'id',
    'machine.machineCode',
    'machine.description',
    'supervisor',
    'operator',
    'material',
    'jobCard.id',
    'jobMaterial.jobCard.id',
    'jobCard.idLotNum',
    'jobMaterial.jobCard.idLotNum',
    'jobMaterial.jobCard.processInstruction.id',
    'jobCard.processInstruction.id',
  ];

  formInput.jobCardArray.forEach((t) => {
    if (t.material) {
      totMeters += +t.material;
    }
  });
  useEffect(() => {
    const currWeight = `${((+totMeters * +weightPercent) / 1000).toFixed(2)}`;
    setTotWeight(currWeight);
  }, [totMeters, weightPercent]);

  useEffect(() => {
    if (pdfData.tableHeader[0].title === '') return;
    async function PdfGenerator() {
      if (pdfData.length === 0) return;
      const blobData = await pdf(
        <PdfComponent
          tableContents={pdfData.tableRows}
          tableHeader={pdfData.tableHeader}
          totalMeters={pdfData.totalMeters}
          totalWeight={pdfData.totalWeight}
          shade={pdfData.shade}
          id={pdfData.id}
          tableTitles={['Process', 'Ind No', 'Item Code', 'Item Name', 'GPL Qty', 'Qty %', 'M.Pre', 'Qty', 'Rec']}
        />,
      ).toBlob();
      const url = await URL.createObjectURL(blobData);
      const aEle = document.createElement('a');
      aEle.target = '_blank';
      aEle.href = url;
      aEle.click();
    }
    PdfGenerator();
  }, [pdfData]);

  return (
    <>
      {popup}
      <SEO title="Machine Utilization" />
      <Toaster />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Machine Utilization</header>
            <CardBody>
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Select Machine</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => machineChange(e)}
                    options={machineOptions}
                    id="machine"
                    name="machine"
                    placeholder="Select Machine"
                    value={defaultMachine}
                    className="pull-down machine require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Supervisor </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="supervisor"
                      id="supervisor"
                      placeholder="Supervisor"
                      onChange={(e) => handleChange(e)}
                      readOnly={!isEditable}
                      value={formInput.supervisor}
                      className="supervisor require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Operator </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="operator"
                      placeholder="operator"
                      onChange={(e) => handleChange(e)}
                      value={formInput.operator}
                      readOnly={!isEditable}
                      className="operator require"
                    />
                  </Input>
                </Col>
                <Col>
                  {formInput.jobCardArray.map((jobCrd, key) => (
                    <Row middle="xs" key={key} className=" jobcards">
                      <Col breakPoint={{ xs: 12, md: 5 }}>
                        <label>Select LotNumber</label>
                        <SelectField
                          fullWidth
                          onChange={(e) => jobcardArrayChange(e, key)}
                          options={jobCardOptions}
                          id={`jobCardArray-${key}-labelField-value`}
                          placeholder="Select LotNumber"
                          value={formInput.jobCardArray[key].labelField}
                          className="pull-down jobcard require"
                        />
                      </Col>
                      <Col breakPoint={{ xs: 12, md: 5 }}>
                        <label>Qty in Meter </label>
                        <Input fullWidth size="Small">
                          <input
                            type="number"
                            name="material"
                            placeholder="material"
                            onChange={(e) => {
                              handleMaterialChange(e, key);
                              checkMax(e, formInput.jobCardArray[key].maxmaterial);
                            }}
                            value={formInput.jobCardArray[key].material}
                            className="material require"
                            readOnly={!formInput.jobCardArray[key].labelField.label || !isEditable}
                          />
                        </Input>
                      </Col>
                      {isEditable && (
                        <Col breakPoint={{ xs: 1, md: 1 }}>
                          <div
                            onClick={(e) => {
                              if (key == 0) return;
                              deleteLot(key);
                            }}
                          >
                            <label className="div-full"></label>
                            <label className="delBtn delItem div-full">
                              <span>-</span>
                            </label>
                          </div>
                        </Col>
                      )}
                    </Row>
                  ))}
                  <Col
                    style={{
                      marginBottom: '15px',
                    }}
                  >
                    <Row middle="xs">
                      {isEditable && (
                        <Button onClick={addNewLot} className="addPibtn">
                          Add Job card <EvaIcon name="plus" className="plusIcon" />
                        </Button>
                      )}
                      <div
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                        Total Meters: <b>{`${totMeters}`}</b>
                      </div>
                      <div
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                        Total Weight: <b>{totWeight}</b>
                      </div>
                      <Button
                        style={{
                          marginLeft: '10px',
                        }}
                        status="Success"
                        className="addPibtn"
                        onClick={getWeight}
                      >
                        Get Weight
                      </Button>
                    </Row>
                  </Col>
                </Col>
                {formInput.macOps.length > 0 && (
                  <Col breakPoint={{ xs: 12 }} className="machine-util-title">
                    Machine Operations
                  </Col>
                )}
                <Col breakPoint={{ xs: 12, md: 12 }} className="pi-operation-container">
                  {formInput.macOps.map((item, key) => {
                    return (
                      <>
                        <Row key={'gropu-' + key} className="pi-input-container machine-inputs" middle="xs">
                          <Col breakPoint={{ xs: 8, md: 2 }}>
                            <label>Group</label>
                            <SelectField
                              fullWidth
                              onChange={(e) => {
                                if (!item.isEditable) return;
                                childGroupChange(e, key);
                              }}
                              options={groupOptions}
                              value={item.defaultGroup}
                              id={'macOps-' + key + '-group'}
                              placeholder="Select Group"
                              className="pull-down require"
                            />
                          </Col>
                          <Col breakPoint={{ xs: 12, md: 3 }}>
                            <label>Operation</label>
                            <SelectField
                              fullWidth
                              onChange={(e) => {
                                if (!item.isEditable) return;
                                childOperationChange(e, key);
                              }}
                              options={item.operationOptions}
                              value={item.defaultOperation}
                              id={'macOps-' + key + '-operation'}
                              placeholder="Select Operation"
                              className="pull-down require"
                            />
                          </Col>

                          {item.isEditable && (
                            <button className="addItembtn" onClick={(e) => getItemsFromJobCard(key)}>
                              Get Items
                            </button>
                          )}
                          <Col breakPoint={{ xs: 12, md: 3 }}>
                            <label>Start Time </label>
                            <Input fullWidth size="Small">
                              <input
                                type="datetime-local"
                                placeholder="Start Date"
                                name="startTime"
                                onChange={(e) => groupInputChange(e, key)}
                                value={item.startTime}
                                readOnly={!item.isEditable}
                                className="startTime"
                              />
                            </Input>
                          </Col>
                          <Col className="endTimer" breakPoint={{ xs: 12, md: 3 }}>
                            <label>End Time </label>
                            <Input fullWidth size="Small">
                              <input
                                type="datetime-local"
                                placeholder="End Date"
                                name="endTime"
                                onChange={(e) => groupInputChange(e, key)}
                                value={item.endTime}
                                readOnly={!item.isEditable}
                                className="endTime"
                              />
                            </Input>
                            {item.duration && <p className={item.valid ? 'green' : 'red'}>{item.duration}</p>}
                          </Col>
                          {item.isEditable && (
                            <div onClick={() => removeOperations(key)} className="div-part10">
                              <label className="div-full"></label>
                              <label className="delBtn piOperationDelete div-full">
                                <span>-</span>
                              </label>
                            </div>
                          )}
                          <Col breakPoint={{ xs: 12, md: 12 }}>
                            {item.indent &&
                              item.indent.map((field, kii) => {
                                return (
                                  <Row key={'item-' + kii} className="item-inputs">
                                    <Col breakPoint={{ xs: 12, md: 2 }}>
                                      {kii == 0 && <label>Indent Number</label>}
                                      <Input fullWidth size="Small">
                                        <input type="text" disabled value={field.intendNumber} />
                                      </Input>
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 3 }}>
                                      {kii == 0 && <label>Item</label>}
                                      <SelectField
                                        fullWidth
                                        onChange={(e) => {
                                          if (!item.isEditable) return;
                                          itemChange(e, key, kii);
                                        }}
                                        options={itemOptions}
                                        placeholder="Select Item"
                                        value={field.defaultItem}
                                        id={'macOps-' + key + '-indent-' + kii + '-item'}
                                        className="pull-down require"
                                      />
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 1 }}>
                                      {kii == 0 && <label>UOM</label>}
                                      <Input fullWidth size="Small">
                                        <input type="text" disabled value={field.uom} />
                                      </Input>
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 1 }}>
                                      {kii == 0 && <label>GPL Qty</label>}
                                      <Input fullWidth size="Small">
                                        <input
                                          type="number"
                                          onChange={(e) => itemInputChange(e, key, kii, 'pct_qty_in_gpl')}
                                          value={field.pct_qty_in_gpl}
                                          readOnly={!item.isEditable}
                                          disabled={+field.pct_qty_in_percentage > 0}
                                        />
                                      </Input>
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 1 }}>
                                      {kii == 0 && <label>Qty in %</label>}
                                      <Input fullWidth>
                                        <input
                                          type="number"
                                          onChange={(e) => itemInputChange(e, key, kii, 'pct_qty_in_percentage')}
                                          value={field.pct_qty_in_percentage}
                                          readOnly={!item.isEditable}
                                          disabled={+field.pct_qty_in_gpl > 0}
                                        />
                                      </Input>
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 1 }}>
                                      {kii == 0 && <label>Mix Prep</label>}
                                      <Input fullWidth size="Small">
                                        <input
                                          type="number"
                                          onChange={(e) => itemInputChange(e, key, kii, 'mix_prep')}
                                          readOnly={!item.isEditable}
                                          disabled={+field.pct_qty_in_percentage > 0}
                                          value={field?.mix_prep}
                                          max={9999}
                                        />
                                      </Input>
                                    </Col>
                                    <Col breakPoint={{ xs: 12, md: 1.1 }}>
                                      {kii == 0 && <label>Quantity </label>}
                                      <Input fullWidth size="Small">
                                        <input
                                          type="text"
                                          placeholder="Quantity"
                                          readOnly={!item.isEditable}
                                          value={field.quantity}
                                          className="operator require"
                                          onChange={(e) => {
                                            itemInputChange(e, key, kii);
                                          }}
                                        />
                                      </Input>
                                    </Col>

                                    <Col breakPoint={{ xs: 12, md: 1 }}>
                                      {kii == 0 && <label>Received</label>}
                                      <SelectField
                                        fullWidth
                                        onChange={(e) => {
                                          if (!item.isEditable) return;
                                          itemFromStoreChange(e, key, kii);
                                        }}
                                        options={sampleBoolean}
                                        placeholder="Select"
                                        value={field.defaultReceivedFromStore}
                                        className="pull-down"
                                      />
                                    </Col>
                                    {item.isEditable && (
                                      <Col className="removeitem" breakPoint={{ xs: 12, md: 0.8 }}>
                                        <div onClick={() => removeItems(key, kii)} className="div-part10">
                                          <label className="div-full"></label>
                                          <label className="delBtn delItem div-full">
                                            <span>-</span>
                                          </label>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                );
                              })}
                            <div className="machinutilitemadd">
                              {item.isEditable && (
                                <button onClick={() => addItem(key)} className="addItembtn">
                                  Add Item +
                                </button>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="PiAddBtnContainer">
                  <Button onClick={() => AddOperation()} className="addPibtn">
                    Add Operation <EvaIcon name="plus" className="plusIcon" />
                  </Button>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="mt20">
                  <Button
                    onClick={postApi}
                    disabled={!isSave}
                    status="Success"
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Machine Utilization</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default MachineUtilization;
